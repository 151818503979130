const parameters = {
  strict: true,
  namespaced: true,
  state: {
    parameters: [],
  },
  getters: {
    getFournisseurPaiement (state) {
      const find = state.parameters.find(item => item.no === 23 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getLongueurCodeFacturation (state) {
      const find = state.parameters.find(item => item.no === 25 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getContratType2Max (state) {
      const find = state.parameters.find(item => item.no === 11 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getPickupDeliveryType1 (state) {
      const find = state.parameters.find(item => item.no === 21 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getPickupDeliveryType2 (state) {
      const find = state.parameters.find(item => item.no === 22 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getPickupDeliveryType3 (state) {
      const find = state.parameters.find(item => item.no === 26 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getModePaiement (state) {
      const find = state.parameters.find(item => item.no === 20 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getJourMaxExEmployee (state) {
      const find = state.parameters.find(item => item.no === 24 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getStepHeure (state) {
      const find = state.parameters.find(item => item.no === 17 && item.type === 'parameters')
      if (find) return parseInt(find.valeur)
      return null
    },
    getAllowHours (state) {
      const find = state.parameters.find(item => item.no === 18 && item.type === 'parameters')
      if (find) {
        return find.valeur.split(',').map(iNum => parseInt(iNum, 10))
      }
      return null
    },
    getAllowDays (state) {
      const find = state.parameters.find(item => item.no === 19 && item.type === 'parameters')
      if (find) {
        return find.valeur.split(',').map(iNum => parseInt(iNum, 10))
      }
      return null
    },
    getWebSite (state) {
      const find = state.parameters.find(item => item.no === 9 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getAdresseCourrielFournisseur (state) {
      const find = state.parameters.find(item => item.no === 12 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getFournisseur (state) {
      const find = state.parameters.find(item => item.no === 10 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getCodeFacturation (state) {
      const find = state.parameters.find(item => item.no === 15 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getLanguage (state) {
      const find = state.parameters.find(item => item.no === 2 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    ifShowOrderLineCreditCard (state) {
      const find = state.parameters.find(item => item.no === 1 && item.type === 'toggles')
      if (find) return find.valeur
      return null
    },
    ifShowOrderLineUbr (state) {
      const find = state.parameters.find(item => item.no === 2 && item.type === 'toggles')
      if (find) return find.valeur
      return null
    },
    ifShowOrderDeviceUbr (state) {
      const find = state.parameters.find(item => item.no === 3 && item.type === 'toggles')
      if (find) return find.valeur
      return null
    },
    getOrderUBRValidationEmail (state) {
      const find = state.parameters.find(item => item.no === 31 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getFraisReActivation (state) {
      const find = state.parameters.find(item => item.no === 41 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
    getInstitutionName (state) {
      const find = state.parameters.find(item => item.no === 45 && item.type === 'parameters')
      if (find) return find.valeur
      return null
    },
  },
  mutations: {
    setParameters (state, payload) {
      state.parameters = payload
    },
  },
  actions: {

  },
}

export default parameters
