import Vue from 'vue'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettingsCodeUQAM, oidcSettingsNodeOIDC } from '@/config/oidc'
import store from '@/store/index'
// import notifier from '@/utils/plugins/notifier'
// Notifier (Snackbar)
// Vue.use(notifier)
let oidcSettings = oidcSettingsCodeUQAM
if (process.env.VUE_APP_OIDC_SERVER === 'NODEOIDC') oidcSettings = oidcSettingsNodeOIDC

const OIDC = vuexOidcCreateStoreModule(
  oidcSettings,
    // Optional OIDC store settings
    {
        namespaced: true,
        dispatchEventsOnWindow: true,
    },
    // Optional OIDC event listeners
    {
        userLoaded: (user) => {
          // Send notification message when user is logged
            // Send message if localStorage is null because user is not connected ()
            if (!localStorage.getItem('welcomeMessage')) {
                // Update local Storage (Reset when 'userUnloaded')
                localStorage.setItem('welcomeMessage', 'connected')
                const language = localStorage.getItem('APP_LANG_KEY')
                let message = `Bonjour ${user.profile.given_name}, vous êtes maintenant connecté(e).`
                if (language === 'en') {
                  message = `Hello ${user.profile.given_name}, You are now connected.`
                } else if (language === 'es') {
                  message = `Buenos dias ${user.profile.given_name}, ahora estas conectado`
                }
                // Send notification (On doit attendre que la vue se charge. C'est pour cela que j'ai mis plusieurs notification à plusieurs intervalle. Mais cela dure quand même que 5 secondes)
                setTimeout(() => {
                  Vue.prototype.$notifier.show({ message: message, color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: false, center: false, left: false, right: true } })
                }, 500)
                // setTimeout(() => {
                //   Vue.prototype.$notifier.show({ message: message, color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: false, center: false, left: false, right: true } })
                // }, 1000)
                // setTimeout(() => {
                //   Vue.prototype.$notifier.show({ message: message, color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: false, center: false, left: false, right: true } })
                // }, 2000)
            }

            // console.log('OIDC user is loaded:', user)
            // Change roles to make them smaller. Ex:'applications/gestionreseau/dns-admin' become 'dns-admin'
            // Update user roles
            const roles = []

            // Roles (Ajouter le rôle dans le backend. Fichier app.js) Important. Ajouter le rôle dans Gestion réseau (app.js)
            // Important. Ajouter le rôle dans Gestion réseau (app.js)
            const roleNiveau0 = ['AGUIRREORTIZ', 'KLECHEROVA_E', 'POIRIER_G']
            const roleAdmin = ['PARE_ST', 'MERCURE_J', 'HERIVAULT_J', 'RUEL_I']
            if (roleAdmin.some(x => x === user.profile.sub.toUpperCase())) roles.push('admin')
            if (roleNiveau0.some(x => x === user.profile.sub.toUpperCase())) roles.push('niveau0')
            // Ex:'applications/gestionreseau/dns-admin' become 'dns-admin'
            // user.profile.roles.forEach(role => {
            //     const arrSplit = role.split('/')
            //     const num = arrSplit.length - 1
            //     roles.push(arrSplit[num])
            // })
            // Mets le rôle 'user' pour tous les utilisateurs connectés
            roles.push('user')

            const utilisateur = {
              name: user.profile.family_name,
              firstName: user.profile.given_name,
              mail: user.profile.email,
              displayName: `${user.profile.family_name}, ${user.profile.given_name}`,
              username: user.profile.email,
              roles: roles,
              codeMs: user.profile.sub,
            }
            store.commit('updateUser', utilisateur)
        },
        userUnloaded: () => {
            // Reset Welcome Message (Check 'userLoaded')
            localStorage.removeItem('welcomeMessage')
            // console.log('OIDC user is unloaded')
            // Reset User info
            store.commit('resetUser')
        },
        accessTokenExpiring: () => {
            // console.log('Access token will expire')
            // The user is allowed 10 mins to move the mouse before the end time of the expire token
            document.addEventListener('mousemove', authenticateSilent)
            /*
      function authenticateSilent(){
        // The client move mouse. Authenticate Silent
        // authenticate Silent
        store.dispatch('oidcStore/authenticateOidcSilent')
        // Stop event listener
        document.removeEventListener("mousemove", authenticateSilent);
      }
      */
        },
        accessTokenExpired: () => {
            // console.log('Access token did expire')
            // Stop event listener
            document.removeEventListener('mousemove', authenticateSilent)
            // OIDC disconnect
            // store.commit('oidcStore/unsetOidcAuth')
            store.dispatch('oidcStore/signOutOidc')
            // document.location.href = '/';
        },
        silentRenewError: () => console.log('OIDC user is unloaded'),
        userSignedOut: () => console.log('OIDC user is signed out'),
    },
)

function authenticateSilent () {
    // console.log('aaaaaaaaa')
    // The client move mouse. Authenticate Silent
    // authenticate Silent
    store.dispatch('oidcStore/authenticateOidcSilent')
    // Stop event listener
    document.removeEventListener('mousemove', authenticateSilent)
}

export default OIDC
