import Vue from 'vue'
import { extend, localize } from 'vee-validate'
// eslint-disable-next-line camelcase
import { required, max, min, regex, between, email, numeric, min_value, length } from 'vee-validate/dist/rules'
import fr from 'vee-validate/dist/locale/fr.json'
import en from 'vee-validate/dist/locale/en.json'
import CommandesService from '@/services/01Cell/CommandesService'
import ContratsService from '@/services/01Cell/ContratsService'

// Install required rule.
extend('required', required)

// Install max rule.
extend('max', max)

// Install min rule.
extend('min', min)

// Install regex rule.
extend('regex', regex)

// Install between rule.
extend('between', between)

// Install email rule.
extend('email', email)

// Install numeric rule. (Chiffre seulement)
extend('numeric', numeric)

// Install min_value rule.
extend('min_value', min_value)

// Install length rule.
extend('length', length)

extend('codeFacturationUQAMDemo', {
    validate: async (code) => {
        if (process.env.VUE_APP_COMPANY === 'demo') {
          return true
        } else if (process.env.VUE_APP_COMPANY === 'uqam') {
          const findCode = await CommandesService.responsableCodeFacturation(code)
          return findCode ? true : 'UBR invalide'
        }
    },
},
{
    hasTarget: true,
})

extend('codeFacturationValidationUQAMDemo', {
    validate: async (code, compte) => {
        if (process.env.VUE_APP_COMPANY === 'demo') {
          return true
        } else if (process.env.VUE_APP_COMPANY === 'uqam') {
            if (code === '000000') return true
            const messageError = {
                'not found': language === 'fr' ? 'UBR non trouvé' : 'UBR not found',
                'not in count': language === 'fr' ? `Le UBR ne peut pas faire de dépense dans le compte (${compte[1]})` : `UBR cannot spend in the account (${compte[1]})`,
            }
            const findUbr = await CommandesService.codeFacturationValidation(code, compte[1])
            // Si il y a une erreur, on envoie l'erreur
            if (findUbr.error) return messageError[findUbr.message]
            // Valide
            return true
        //   return findCode ? true : 'UBR invalide'
        }
    },
},
{
    hasTarget: true,
})

extend('numberPhoneIsDuplicates', {
    validate: async (phoneNumber) => {
        // On regarde si il y a déjà un contrat associé au numéro de téléphone
        const contract = await ContratsService.validUnique(phoneNumber)
        // Si on trouve un contrat, le client ne peut pas utiliser ce numéro
        if (contract) {
          if (language === 'fr') return 'Le numéro de téléphone est déjà utilisé'
          return 'The phone number is already in use'
        } else {
          return true
        }
    },
},
{
    hasTarget: true,
})

extend('validCardMonth', {
    validate: async (monthFields, yearFields) => {
        // Si pas d'année de sélectionné on retourne true
        if (!yearFields) return true

        const fullYear = `20${yearFields[0]}`
        const fullYearToNumber = parseInt(fullYear, 10)
        const fullMonthToNumber = parseInt(monthFields, 10)

        const d = new Date()
        const year = d.getFullYear()
        const month = d.getMonth() + 1

        // Si l'année est égale, on vérifie le mois
        if (fullYearToNumber === year) {
            // Si même année, il faut que le mois sélectionné par le client soit plus grand que le mois en cours
            if (fullMonthToNumber < month) {
                if (language === 'fr') return 'Le mois ne correspond pas'
                return 'The month does not match'
            }
        }

        // // Si l'année de la carte de crédit est plus grande que l'année c'est valide
        // if (fullYearToNumber > year) return false
        // // Si l'année de la carte de crédit est plus petite que l'année c'est invalide (On affichee l'îcone)
        // if (fullYearToNumber < year) return true

        // // Si l'année est égale, on vérifie le mois
        // const month = d.getMonth() + 1
        // // Si le mois est plus petit c'est invalide (On affiche l'îcone)
        // if (parseInt(monthFields, 10) < month) return true

        return true
    },
},
{
    hasTarget: true,
})

// Install English and French localizations.
localize({
    en: {
        messages: en.messages,
        names: {
          Username: 'Username',
        },
        fields: {
            busyTrigger: {
                // required: 'Le champ Busy Trigger est obligatoire?',
                phoneCallWaiting: 'The Busy Trigger field must be lower than the Maximum "Number of Calls" field',
            },
        },
    },
    fr: {
        messages: fr.messages,
        names: {
          Username: 'Nom d\'utilisateur',
        },
        fields: {
            busyTrigger: {
                // required: 'Le champ Busy Trigger est obligatoire?',
                phoneCallWaiting: 'Le champ Busy Trigger doit être moins élevé que le champs "Maximum Number of Calls"',
            },
        },
    },
})

// Get language
const language = localStorage.getItem('APP_LANG_KEY') || 'fr'
// Set Language. Important, La fonction localize est aussi dans le component 'HeaderHorizontal.vue' pour changer la langue
localize(language)
// Store language for get function
let LOCALE = language
// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, 'locale', {
    get () {
      return LOCALE
    },
    set (val) {
        LOCALE = val
        localize(val)
    },
})
